<template>
  <div class="d-flex align-items-center justify-content-center user-select-none vh-100 mx-sm-0 mx-4">
    <transition appear name="fade">
      <div>
        <particles id="particles-bg" :options="particlesOptions" />
        <div class="d-flex flex-column">
          <img src="images/logo-white-shadow.svg" class="mx-5 p-3 pointer-events-none" alt="Logo">
          <h1 class="display-4 text-center text-uppercase text-white" style="text-shadow: 0 0 10px rgb(0 0 0 / 50%)">
            Ligne d'urgence
          </h1>
          <form @submit.prevent="handleLogin">
            <label for="username">Username</label>
            <input type="text" v-model="username" />

            <label for="password">Password</label>
            <input type="password" v-model="password" />

            <button type="submit">
              Login
            </button>
          </form>

          <p v-if="errorMessage" class="error">
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import { Vue } from 'vue-property-decorator'
import Particles from 'particles.vue'
import axios from '@/libs/axiosIns'
import jwtDecode from 'jwt-decode'
import store from '@/store'

Vue.use(Particles)

export default {
  name: 'Landing',
  data() {
    return {
      particlesOptions: {
        background: {
          color: '#fff',
          image: 'url(\'/images/refuge.jpg\')',
          position: 'bottom center',
          size: 'cover',
        },
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'window',
          events: {
            onHover: {
              enable: true,
              mode: 'bubble',
              parallax: {
                enable: true,
                force: 140,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 140,
              duration: 2,
              opacity: 0.8,
              size: 20,
            },
          },
        },
        particles: {
          color: {
            value: '#fff',
          },
          links: {
            enable: false,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'out',
            random: true,
            speed: 0.7,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 800,
            },
            value: 60,
          },
          opacity: {
            value: 0.4,
          },
          rotate: {
            random: {
              enable: true,
            },
            animation: {
              enable: true,
              speed: 3,
            },
            direction: 'random',
          },
          shape: {
            options: {
              image: [
                {
                  src: '/images/icon-white.svg',
                  height: 32,
                  width: 32,
                },
              ],
            },
            type: 'image',
          },
          size: {
            random: true,
            value: 10,
          },
        },
        detectRetina: true,
      },
      username: '',
      password: '',
      errorMessage: null,
    }
  },
  methods: {
    async handleLogin() {
      try {
        // Send login request to backend
        const response = await axios.post('/Auth/Login', {
          login: this.username,
          password: this.password,
        })

        // Assuming the token is returned as `access_token`
        const token = response.data.access_token
        if (token) {
          // Save token to localStorage
          localStorage.setItem('token', token)

          // Decode the token and get the user ID
          const decodedToken = jwtDecode(token)
          const userId = decodedToken.sub

          // Save user ID to Vuex store
          store.commit('auth/setUserId', userId)

          // Save token to Vuex if needed
          store.commit('auth/setToken', token)

          // Set default Authorization header
          axios.defaults.headers.common.Authorization = `Bearer ${token}`

          // Redirect user to a protected page (e.g., home page)
          this.$router.push({ name: 'planning' })
        }
      } catch (error) {
        // Handle login error
        this.errorMessage = 'Invalid username or password'
      }
    },
  },
}
</script>

<style>
#particles-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: -10;
}
</style>
